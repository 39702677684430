import { portfolioAdapter } from '@/adapters/portfolio';
import { ServiceData, ServiceQuery } from '../service';

import { Portfolio } from '@models/portfolio';
import { FilterRequestParam } from '@models/table';

interface GetData extends ServiceData {
  portfolios: Portfolio[];
}

interface GetParams {
  filter: FilterRequestParam[];
}

const get: ServiceQuery<GetData, GetParams> = async (context) => {
  const { queryKey, signal } = context;
  const [{ filter = [] }] = queryKey;

  const { data, meta } = await portfolioAdapter.get({ filter }, { signal });

  return { portfolios: data, meta };
};

export type { GetData, GetParams };
export { get };
