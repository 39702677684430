import { institutionAdapter } from '@/adapters/institution';
import { ServiceData, ServiceQuery } from '../service';

// import { EnabledInstitution } from '@models/institution';

interface GetData extends ServiceData {
  institutions: any[];
}

const getEnabled: ServiceQuery<GetData> = async (context) => {
  const { signal } = context;

  const { data, meta } = await institutionAdapter.getEnabled({ signal });

  return { institutions: data, meta };
};

export type { GetData };
export { getEnabled };
