import { portfolioAdapter } from '@adapters/portfolio';
import { ServiceData, ServiceQuery } from '../service';

interface GetData extends ServiceData {
  validPortfolios: string[];
}

interface GetParams {
  portfolioIds: string[];
}

const getGroup: ServiceQuery<GetData, GetParams> = async (context) => {
  const { queryKey, signal } = context;
  const [{ portfolioIds }] = queryKey;

  const parsedPortfolioIds = portfolioIds.map((id) => [
    {
      key: 'source_portfolio_id',
      value: id,
      type: 'string'
    }
  ]);

  const promises = parsedPortfolioIds.map((portIdFilter) =>
    portfolioAdapter.get({ filter: portIdFilter }, { signal })
  );

  const responses = (await Promise.allSettled(promises)) as any;

  const validPortfolios = responses
    .filter(
      (portfolio) =>
        portfolio.status === 'fulfilled' &&
        portfolio.value.data.length > 0 &&
        portfolio.value.data[0].isActive
    )
    .map((portfolio) => portfolio.value.data[0].sourcePortfolioId);

  return { validPortfolios };
};

export type { GetData, GetParams };
export { getGroup };
