import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { Amplify, Hub, Auth } from 'aws-amplify';

import { ToastVariant, useToasts } from '@library/components/toasts';

import { translate } from '@/i18n';

import { useInstitution } from './institution';
import { Datadog } from '../datadog';

interface UserProfile {
  id: string;
  lastName: string;
  firstName: string;
  email: string;
  institutionId: string[];
  scopes: string[];
  products: string[];
}

interface AuthProviderProps {
  children: React.ReactNode;
}

type AuthContextType = {
  userProfile: UserProfile;
  isCdlxUser: boolean;
  isAdminUser: boolean;
  isWriteUser: boolean;
  isReadUser: boolean;
  logout: () => void;
};

const getInstitutionId = (institutionIds: string[], isCdlxUser: boolean) => {
  const sessionStorageInstitutionId = sessionStorage.getItem(
    'SST_FIID'
  ) as string;

  return Boolean(sessionStorageInstitutionId) &&
    (institutionIds.includes(sessionStorageInstitutionId) || isCdlxUser)
    ? sessionStorageInstitutionId
    : institutionIds[0];
};

const getParsedUserProfile = (data): UserProfile => {
  const {
    email,
    family_name: lastName,
    given_name: firstName,
    'custom:preprod_institution': institutionId,
    'custom:product': products,
    'custom:scopes': scopes,
    sub
  } = data.signInUserSession.idToken.payload;

  const parsedProducts = products ? products.split('|') : [];
  const parsedScopes = scopes ? scopes.split('|') : [];
  const parsedInstitutionId = institutionId ? institutionId.split(',') : ['0'];

  return {
    id: sub,
    lastName,
    firstName,
    email,
    institutionId: parsedInstitutionId,
    products: parsedProducts,
    scopes: parsedScopes
  };
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth can only be used within an InstitutionContext');
  }
  return context;
};

const cognitoInit = ({
  setUserProfile,
  showToast
}: {
  setUserProfile: Dispatch<SetStateAction<UserProfile>>;
  showToast: ({
    variant,
    content
  }: {
    variant: ToastVariant;
    content: string;
  }) => void;
}) => {
  const {
    DOMAIN,
    SCOPES,
    REDIRECT_SIGN_IN,
    REDIRECT_SIGN_OUT,
    RESPONSE_TYPE,
    AWS_REGION,
    USER_POOL_ID,
    USER_POOL_WEB_CLIENT_ID
  } = process.env.COGNITO;

  const oauth = {
    scopes: SCOPES,
    domain: DOMAIN,
    redirectSignIn: REDIRECT_SIGN_IN,
    redirectSignOut: REDIRECT_SIGN_OUT,
    responseType: RESPONSE_TYPE
  };

  Amplify.configure({
    Auth: {
      region: AWS_REGION,
      userPoolId: USER_POOL_ID,
      userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
      oauth
    },
    Logging: {
      logLevel: 'verbose'
    }
  });

  // Most of the event names cover on cases in case we need to handle something different in the future
  Hub.listen('auth', ({ payload: { event, data } }) => {
    switch (event) {
      case 'signIn': {
        {
          if (data) {
            const userProfile = getParsedUserProfile(data);
            setUserProfile(userProfile);

            Datadog.setUser({
              id: userProfile.id,
              email: userProfile.email,
              firstName: userProfile.firstName,
              lastName: userProfile.lastName
            });

            // TODO: set user in launchdarkly
          }

          break;
        }
      }
      case 'signUp': {
        {
          break;
        }
      }
      case 'signOut': {
        {
          break;
        }
      }
      case 'signIn_failure': {
        {
          showToast({
            variant: 'variant.error',
            content: translate('common.userError')
          });

          setTimeout(() => {
            logout();
          }, 2500);

          break;
        }
      }
      case 'parsingCallbackUrl': {
        {
          break;
        }
      }
      case 'configured': {
        {
          break;
        }
      }
      case 'cognitoHostedUI': {
        {
          break;
        }
      }
    }
  });
};

const logout = async () => {
  try {
    await Auth.signOut({ global: true });
    window.location.replace(`${window.location.origin}/login`);
  } catch (error) {
    console.error('signOut error:', error);
  }
};

const isRoleAssigned = (userProfile: UserProfile, roles: string[]) =>
  userProfile &&
  userProfile.scopes &&
  userProfile.scopes.some((role) => roles.includes(role));
const AuthProvider = ({ children }: AuthProviderProps) => {
  const [userProfile, setUserProfile] = useState<UserProfile>(
    {} as UserProfile
  );

  const { sendToast } = useToasts();
  const { setCurrentInstitutionId } = useInstitution();

  useEffect(() => {
    //setting userProfile in case the user refresh the page with a valid session
    const getUserProfile = async () => {
      const currentUser = await Auth.currentAuthenticatedUser();
      const parsedUserProfile = currentUser
        ? getParsedUserProfile(currentUser)
        : ({} as UserProfile);

      setUserProfile(parsedUserProfile);

      if (parsedUserProfile?.id) {
        Datadog.setUser({
          id: parsedUserProfile.id,
          email: parsedUserProfile.email,
          firstName: parsedUserProfile.firstName,
          lastName: parsedUserProfile.lastName
        });
      }
    };

    getUserProfile();
  }, []);

  useEffect(() => {
    if (userProfile.firstName) {
      const targetInstitutionId = getInstitutionId(
        userProfile.institutionId,
        isCdlxUser
      );

      setCurrentInstitutionId(targetInstitutionId);
    }
  }, [userProfile]);

  const isCdlxUser = useMemo(() => {
    const cdlxRoles = [
      ...process.env.CDLX_ROLES.ADMIN,
      ...process.env.CDLX_ROLES.WRITE,
      ...process.env.CDLX_ROLES.READ
    ];

    return isRoleAssigned(userProfile, cdlxRoles);
  }, [userProfile]);

  const isAdminUser = useMemo(() => {
    const adminRoles = process.env.CDLX_ROLES.ADMIN;

    return isRoleAssigned(userProfile, adminRoles);
  }, [userProfile]);

  const isWriteUser = useMemo(() => {
    const writeRoles = [
      ...process.env.CDLX_ROLES.WRITE,
      ...process.env.BANK_ROLES.WRITE,
      ...process.env.CDLX_ROLES.ADMIN
    ];

    return isRoleAssigned(userProfile, writeRoles);
  }, [userProfile]);

  const isReadUser = useMemo(() => {
    const readRoles = [
      ...process.env.CDLX_ROLES.READ,
      ...process.env.BANK_ROLES.READ
    ];

    return isRoleAssigned(userProfile, readRoles);
  }, [userProfile]);

  const showToast = ({
    variant,
    content
  }: {
    variant: ToastVariant;
    content: string;
  }) => {
    sendToast({
      variant,
      content
    });
  };

  cognitoInit({ setUserProfile, showToast });

  const contextValue = useMemo(
    () => ({
      userProfile,
      isCdlxUser,
      isAdminUser,
      isWriteUser,
      isReadUser,
      logout
    }),
    [userProfile, logout]
  );
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export { AuthProvider, useAuth };
