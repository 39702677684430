import { apiTransport } from '@services/rest-api';
import { AdapterFunction } from '../types';

import { Portfolio } from '@models/portfolio';
import { FilterRequestParam } from '@models/table';

import { getFilterString } from '@utils/rest-api';

interface GetParams {
  filter: FilterRequestParam[];
}

const get: AdapterFunction<Portfolio[], GetParams> = (
  { filter },
  { signal }
) => {
  const parsedFilter =
    getFilterString(filter).length > 0 ? `?${getFilterString(filter)}` : '';

  const url = `/v1/portfolios${parsedFilter}`;
  const response = apiTransport.get<Portfolio[]>({
    url,
    config: {
      signal
    }
  });

  return response;
};

export type { GetParams };
export { get };
