import { apiTransport } from '@services/rest-api';
import { AdapterFunction } from '../types';
import { Organization } from '@models/organization';
import { FilterRequestParam } from '@models/table';
import { getFilterString } from '@utils/rest-api';

interface GetParams {
  filter: FilterRequestParam[];
}

const get: AdapterFunction<Organization[], GetParams> = (
  { filter },
  { signal }
) => {
  const parsedFilter =
    getFilterString(filter).length > 0 ? `?${getFilterString(filter)}` : '';

  const url = `/v1/organizations${parsedFilter}`;

  const response = apiTransport.get<Organization[]>({
    url,
    config: {
      signal
    }
  });

  return response;
};

export type { GetParams };
export { get };
