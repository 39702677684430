import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import {
  HorizontalLoader,
  PrimaryButton,
  SecondaryButton,
  Tooltip,
  useToasts
} from '@/library/components';
import { TemplateList } from './TemplateList';
import { TargetCustomerList } from './TargetCustomerList';

import { useApiMutation } from '@hooks/use-api-mutation';

import { CreateOfferRequest, Offer } from '@models/campaign';
import { Customer } from '@models/customer';

import { offerService } from '@services/offer';

import './AddOffersModalContent.css';
import { Datadog } from '@/datadog';

interface AddOffersModalContentProps {
  offers: Offer[];
  customers: Customer[];
  isLoadingOffers: boolean;
  isLoadingCustomers: boolean;
  totalOffers: number;
  totalCustomers: number;
  customerPagination: {
    pageIndex: number;
    pageSize: number;
  };
  offerPagination: {
    pageIndex: number;
    pageSize: number;
  };
  selectedCampaigns: Offer[];
  selectedCustomers: Customer[];
  customerFilterText: string;
  onFilterCustomers: (searchText) => void;
  onSelectCampaignChange: (arg) => void;
  onSelectCustomerChange: (arg) => void;
  onOfferPaginationChange: (arg) => void;
  onCustomerPaginationChange: (arg) => void;
  close: () => void;
}

const AddOfferRowLoading = () => (
  <div className='my-2 flex flex-row justify-around py-2'>
    <div className='px-2'>
      <div className='pulsate mb-2 h-20 w-20 animate-pulsate' />
    </div>
    <div className='w-full px-2'>
      <div className='pulsate mb-2 h-4 w-full animate-pulsate' />
      <div className='pulsate mb-2 h-4 w-3/5 animate-pulsate' />
    </div>
    <div className='w-full px-2'>
      <div className='pulsate mb-2 h-4 w-full animate-pulsate' />
      <div className='pulsate mb-2 h-4 w-3/5 animate-pulsate' />
      <div className='pulsate mb-2 h-4 w-4/5 animate-pulsate' />
    </div>
    <div className='w-full px-2'>
      <div className='pulsate mb-2 h-4 w-3/5 animate-pulsate' />
      <div className='pulsate mb-2 h-4 w-full animate-pulsate' />
      <div className='pulsate mb-2 h-4 w-4/5 animate-pulsate' />
    </div>
  </div>
);

const AddOffersModalContent = ({
  offers,
  customers,
  isLoadingOffers,
  isLoadingCustomers,
  offerPagination,
  customerPagination,
  totalOffers,
  totalCustomers,
  selectedCampaigns,
  selectedCustomers,
  customerFilterText,
  onFilterCustomers,
  onSelectCustomerChange,
  onSelectCampaignChange,
  onOfferPaginationChange,
  onCustomerPaginationChange,
  close
}: AddOffersModalContentProps) => {
  const [t] = useTranslation();

  const [isOfferListVisible, setIsOfferListVisible] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [createOfferResponse, setCreateOfferResponse] = useState([]);
  const [creationErrorMessage, setCreationErrorMessage] = useState('');

  const { sendToast } = useToasts();
  const queryClient = useQueryClient();
  const createMutation = useApiMutation(offerService.create());

  const handleAddOffersForCustomers = () => {
    const campaignIds = selectedCampaigns.map((offer) => offer.campaignId);
    const customerIds = selectedCustomers.map(
      (customerInstance) => customerInstance.customerId
    );

    const payload = {
      accountId: selectedCampaigns[0].accountId,
      campaignIds,
      customerIds: customerIds as number[]
    };

    handleAddRequest(payload);
    setIsOfferListVisible(true);
  };

  const handleAddOffersForAll = () => {
    const campaignIds = selectedCampaigns.map((offer) => offer.campaignId);
    const customerIds = [];

    const payload = {
      accountId: selectedCampaigns[0].accountId,
      campaignIds,
      customerIds: customerIds as number[]
    };

    handleAddRequest(payload);
    setIsOfferListVisible(true);
  };

  const handleAddRequest = (payload: CreateOfferRequest) => {
    setIsSaving(true);

    createMutation.mutate(
      {
        payload
      },
      {
        async onSuccess({ offer: offersResponse }) {
          setCreateOfferResponse(offersResponse.data.response);
          setIsSaving(false);

          const allSuccess = offersResponse.data.response.every(
            (offer) => offer.state >= 200 && offer.state < 300
          );

          const allError = offersResponse.data.response.every(
            (offer) => offer.state >= 400 && offer.state < 600
          );

          const someError = offersResponse.data.statusCode === 207;

          if (allSuccess) {
            sendToast({
              variant: 'variant.success',
              content: `${t('label.allOffersAdded', {
                count: selectedCampaigns.length
              })}`
            });
            close();
          }

          if (allError) {
            sendToast({
              variant: 'variant.error',
              content: `${t('common.createOfferError')}`
            });
            Datadog.sendError(offersResponse.data);
            setCreationErrorMessage(
              'None of the campaigns were created. Please try again.'
            );
          }

          if (someError) {
            sendToast({
              variant: 'variant.error',
              content: `${t('common.createOfferError')}`
            });
            Datadog.sendError(offersResponse.data);
            setCreationErrorMessage(
              'Some campaigns were not created. Please check the selected campaigns list.'
            );
          }

          setIsSaving(false);

          // // invalidate cache to request org list again after redirection
          await queryClient.invalidateQueries({
            queryKey: offerService.invalidationKey
          });
        },
        onError(error) {
          Datadog.sendError(error);
          Datadog.sendEvent('CreateOfferError', error);
          setCreationErrorMessage('Unknown error. Please contact support.');
          setIsSaving(false);
        }
      }
    );
  };

  return (
    <div className='AddOffersModalContent w-256 p-4'>
      {isOfferListVisible ? (
        <TemplateList
          offers={offers}
          isLoading={isLoadingOffers}
          totalOffers={totalOffers}
          pagination={offerPagination}
          selectedCampaigns={selectedCampaigns}
          creationErrorMessage={creationErrorMessage}
          createOfferResponse={createOfferResponse}
          onPaginationChange={onOfferPaginationChange}
          onSelectCampaignChange={onSelectCampaignChange}
        />
      ) : (
        <TargetCustomerList
          isLoading={isLoadingCustomers}
          customers={customers}
          pagination={customerPagination}
          totalCustomers={totalCustomers}
          selectedCustomers={selectedCustomers}
          customerFilterText={customerFilterText}
          onFilterCustomers={onFilterCustomers}
          onSelectCustomerChange={onSelectCustomerChange}
          onPaginationChange={onCustomerPaginationChange}
        />
      )}
      {isOfferListVisible ? (
        <div className='mt-6 flex justify-end'>
          <div className='relative flex items-center'>
            <div className='relative'>
              <PrimaryButton
                size='size.md'
                disabled={selectedCampaigns.length === 0 || isSaving}
                onClick={() => {
                  setIsOfferListVisible(false);
                }}
              >
                {isSaving
                  ? t('common.savingEllipsis')
                  : t('label.selectCustomers')}
              </PrimaryButton>
              <HorizontalLoader isActive={isSaving} />
            </div>
          </div>
        </div>
      ) : (
        <div className='mt-6 flex justify-between'>
          <SecondaryButton
            size='size.md'
            onClick={() => {
              setIsOfferListVisible(true);
            }}
          >
            {t('label.backToOffers')}
          </SecondaryButton>
          <div className='flex items-center'>
            <div className='relative'>
              <Tooltip content={t('common.createForAllCustomers')}>
                <SecondaryButton
                  size='size.md'
                  disabled={selectedCampaigns.length === 0 || isSaving}
                  onClick={handleAddOffersForAll}
                >
                  {t('label.createOfferForAll')}
                </SecondaryButton>
              </Tooltip>
              <HorizontalLoader isActive={isSaving} />
            </div>
            <div className='relative ml-2'>
              <Tooltip content={t('common.createForSelectedCustomers')}>
                <PrimaryButton
                  size='size.md'
                  onClick={handleAddOffersForCustomers}
                  disabled={selectedCustomers.length === 0 || isSaving}
                >
                  {t('label.createOfferForCustomers')}
                </PrimaryButton>
              </Tooltip>
              <HorizontalLoader isActive={isSaving} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { AddOffersModalContent, AddOfferRowLoading };
